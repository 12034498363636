import { isMobile } from "../common/_constants";

export const toggleEditionDropdown = (event) => {
    const editionsContainer = document.querySelector("#editionsContainer");
    const editionDropdown = document.querySelector(
        "#block-tsndomainnavigation .menu",
    );
    const editionsLink = document.querySelectorAll(
        "#block-tsndomainnavigation .menu a.link",
    );

    if (!editionsContainer) return;

    if (!editionDropdown.hasEventListener) {
        editionDropdown.hasEventListener = true;
        editionDropdown.addEventListener("click", () => {
            editionDropdown.classList.toggle("open");
        });
    }

    // Handles editions dropdown
    if (isMobile()) {
        editionsContainer.className = "editions-mobile";
    } else {
        editionsContainer.className = "tsn-account-menu-wrapper";
    }

    // Handles edition cookies
    if (event) {
        for (const link of editionsLink) {
            const cookieName = "sn-edition";
            const cookieTtl = 30;
            const target = event.currentTarget;
            const edition = target.dataset.edition || "";

            link.addEventListener("click", () => {
                // Check for valid edition
                if (
                    edition.length === 0 ||
                    !document.querySelector(
                        `#block-tsndomainnavigation .menu .link[data-edition='${edition}']`,
                    )
                ) {
                    return true;
                }

                // Set the cookie
                document.cookie = `${cookieName}=${edition}; expires=${new Date(Date.now() + cookieTtl * 24 * 60 * 60 * 1000).toUTCString()}`;

                return true;
            });
        }
    }
};

export const setEditionItemsWidth = () => {
    const editionItems = document.querySelectorAll(
        "#desktopEditionsMenu [data-edition-item]",
    );
    let longestEditionWidth = 0;

    editionItems.forEach((edition) => {
        if (edition.clientWidth > longestEditionWidth) {
            longestEditionWidth = edition.clientWidth;
        }
    });

    editionItems.forEach((edition) => {
        edition.style.width = `${longestEditionWidth}px`;
    });
};
