import handleMediaQueryChange from "../components/helpers/_media_query";

const adjustMobileFooterForAds = () => {
    const footer = document.querySelector("#new-footer-layout");
    const banner = document.querySelector(".header-advertising");

    if (!banner || !footer) return;

    const BANNER_PADDING = 10;

    footer.style.marginBottom = `${banner.clientHeight - BANNER_PADDING}px`;
};

const adjustDesktopFooterForAds = () => {
    const footer = document.querySelector("#new-footer-layout");

    if (!footer) return;

    footer.style.marginBottom = "0";
};

export const setFooterSpacing = () => {
    handleMediaQueryChange(adjustMobileFooterForAds, adjustDesktopFooterForAds);
};
